import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'
import Parallax from '../../UI/Parallax'
import arrowRight from '../../../../content/icons/arrowRight.svg'
import arrowLeft from '../../../../content/icons/arrowLeft.svg'
import bubbles from '../../../../content/images/bubbles.svg'

const Partner = ({ data: { partners, partners_title: title } }) => {
  const dataLength = partners.length

  const sliderRef = React.useRef()
  const [slideIndex, setSlideIndex] = React.useState(0)
  const [slideWidth, setSlideWidth] = React.useState(0)

  const handleSlideWidth = () => {
    const { width } = sliderRef.current.getBoundingClientRect()
    const widthWidth = global.window.innerWidth
    let column = 1

    if (widthWidth > 900) {
      column = 3
    } else if (widthWidth > 600) {
      column = 2
    }

    setSlideIndex(0)
    setSlideWidth(width / column - 16 * (column - 2))
  }

  React.useEffect(() => {
    handleSlideWidth()
  }, [])

  React.useEffect(() => {
    global.window.addEventListener('resize', handleSlideWidth)

    return () => {
      global.window.removeEventListener('resize', handleSlideWidth)
    }
  }, [])

  const prevSlide = () => setSlideIndex(ps => (ps - 1) % dataLength)
  const nextSlide = () => setSlideIndex(ps => (ps + 1) % dataLength)

  const renderPartner = partners.map(item => (
    <S.Card key={item.description} style={{ minWidth: slideWidth }}>
      <S.CardImage src={item.image.url} />
      <S.Text>{item.name}</S.Text>
      <S.City>{item.city}</S.City>
      <S.Description>"{item.description}"</S.Description>
    </S.Card>
  ))

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Heading>{title}</S.Heading>
        <Parallax force={2.2} offsetComp={60}>
          {offSet => (
            <S.Bubbles
              src={bubbles}
              alt="Partners"
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
            />
          )}
        </Parallax>
        <S.Slider>
          <S.SliderSCroll
            ref={sliderRef}
            style={{
              transform: `translateX(-${(slideWidth + 16) * slideIndex}px)`,
            }}
          >
            {renderPartner}
          </S.SliderSCroll>
          <S.Nav>
            <button
              type="button"
              onClick={prevSlide}
              disabled={slideIndex === 0}
              style={{ opacity: slideIndex === 0 ? 0.3 : 1 }}
            >
              <img src={arrowLeft} alt="previous" />
            </button>

            <button
              type="button"
              onClick={nextSlide}
              disabled={slideIndex + 1 === dataLength}
              style={{ opacity: slideIndex + 1 === dataLength ? 0.3 : 1 }}
            >
              <img src={arrowRight} alt="next" />
            </button>
          </S.Nav>
        </S.Slider>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

Partner.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default Partner
