import React from 'react'

import SVG from '../../UI/SVG'
import { UpDown, UpDownWide } from '../../../styles/animations'
import { theme } from '../../../styles'

import * as S from './styled'

const Bubbles = () => (
  <S.Wrapper>
    <UpDownWide>
      <SVG icon="circle" hiddenMobile width={48} fill={theme.colors.orange} left="5%" top="5%" />
      <SVG icon="circle" width={30} fill={theme.colors.orange} left="29%" top="1%" />
      <SVG icon="circle" width={20} fill={theme.colors.grey} left="35%" top="10%" />
    </UpDownWide>
    <UpDown>
      <SVG icon="circle" hiddenMobile width={10} fill={theme.colors.black} left="29%" top="3%" />
      <SVG icon="circle" width={52} fill={theme.colors.orange} left="42%" top="0%" />
    </UpDown>
    <UpDown>
      <SVG icon="circle" hiddenMobile width={52} fill={theme.colors.black} left="36%" top="3%" />
      <SVG icon="circle" width={52} fill={theme.colors.grey} left="66%" top="8%" />
    </UpDown>
    <UpDownWide>
      <SVG icon="circle" hiddenMobile width={80} fill={theme.colors.black} left="9%" top="25%" />
      <SVG icon="circle" width={10} fill={theme.colors.orange} left="75%" top="1%" />
      <SVG icon="circle" width={20} fill={theme.colors.orange} left="95%" top="0%" />
    </UpDownWide>
    <UpDown>
      <SVG icon="circle" hiddenMobile width={70} fill={theme.colors.orange} left="80%" top="3%" />
      <SVG icon="circle" width={52} fill={theme.colors.grey} left="70%" top="0%" />
    </UpDown>
    <UpDownWide>
      <SVG icon="circle" hiddenMobile width={70} fill={theme.colors.orange} left="20%" top="25%" />
      <SVG icon="circle" width={52} fill={theme.colors.black} left="75%" top="1%" />
    </UpDownWide>
  </S.Wrapper>
)

export default Bubbles
