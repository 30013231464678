import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { LocaleContext } from '../../Layout'
import Parallax from '../../UI/Parallax'
import * as S from './styled'

const TopSection = ({
  id,
  data: {
    top_section_title: title,
    top_section_subtitle_1_black: subtitleBlack1,
    top_section_subtitle_1_orange: subtitleOrange1,
    top_section_subtitle_2_black: subtitleBlack2,
    top_section_subtitle_2_orange: subtitleOrange2,
    top_section_description: description,
    top_section_button: button,
    top_section_image: { url },
  },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const isPt = lang.locale === 'pt-pt'

  return (
    <S.Wrapper id={id}>
      <S.RowTitle>
        <S.SectionTitle>{title}</S.SectionTitle>
      </S.RowTitle>
      <S.RowContent>
        <S.ColLeft>
          <S.Subtitle>
            {subtitleBlack1} <span>{subtitleOrange1}</span>.
          </S.Subtitle>
          <S.Subtitle>
            {subtitleBlack2} <span>{subtitleOrange2}</span>.
          </S.Subtitle>
          <S.Text>{description}</S.Text>
          <Link to={isPt ? `/${i18n.link1}` : `/en/${i18n.link1}`}>
            <S.Button>{button}</S.Button>
          </Link>
        </S.ColLeft>
      </S.RowContent>
      <Parallax force={1.2} offsetComp={30}>
        {offSet => (
          <S.ImageWrapper
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
          >
            <S.Image src={url} alt={title} />
            <svg width="0" height="0">
              <defs>
                <clipPath id="myClip">
                  <path d="M77.973 312.764c-21.43 250.816 137.013 385.253 219.974 424.8 255.264 121.683 111.876 280.58 340.807 292.142 57.35 2.897 154.49-93.072 168.168-98.85 22.62-9.557 9.87-56.98 40-128 30.13-71.021 38.62-66.015 96.728-66.015 0 0 55.955-21.521 55.955-66.717s160.144-458.411-78.112-503.607c-223.855-42.464-273.01-26.965-585.055-81.987C246.013 68.586 99.403 61.948 77.973 312.764z" />
                </clipPath>
              </defs>
            </svg>
          </S.ImageWrapper>
        )}
      </Parallax>
    </S.Wrapper>
  )
}

TopSection.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
}
export default TopSection
