import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'

import url from '../../../../content/images/background-breed.png'

import { Button as DefaultButton } from '../../UI/Buttons'
import { Col as DefaultCol, Row as DefaultRow } from '../../UI/Grid'

export const BackgroundWrapper = styled.div`
  min-height: 1180px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 100;
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  background-image: url(${url});

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    background-size: contain;
    min-height: 100%;
    transform: none;
    background-position: left top;
  }
`

export const Wrapper = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: center;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth};
  width: 100%;
  padding: 0 16px;
  /* transform: translateY(260px);

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    transform: translateY(160px);
  } */
`
export const SvgWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    display: none;
  }
`
export const UpperCol = styled(DefaultCol)`
  width: 100%;
  max-width: 512px;
`

export const Col = styled(DefaultCol)``

export const ThumbCol = styled(Col)`
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-right: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-grow: 1;
    margin-right: 0;
  }
`
export const ContentCol = styled(Col)`
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 100%;
  }

  @media (min-width: 601px) and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-left: 16px;
  }
`
export const Row = styled(DefaultRow)`
  padding: 0;
`
export const CardRow = styled(Row)`
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    justify-content: flex-start;
  }
`

export const ContentRow = styled(Row)`
  margin-top: 48px;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;
  }
`
export const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  padding-top: 16px;
`

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.bg};
  border: 1px solid #ecebf5;
  box-shadow: 0 2px 16px 0 rgba(21, 30, 41, 0.06);
  border-radius: 4px;

  max-width: 378px;
  padding: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-top: 32px;
    max-width: 100%;
  }
`

export const Link = styled(GatsbyLink)`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    justify-content: flex-start;
  }
`
export const BreedTitle = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 16px 0;
`
export const Text = styled.p`
  font-size: 0.9rem;
  padding-bottom: 16px;
`

export const Button = styled(DefaultButton)`
  width: 100%;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    max-width: 217px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 100%;
  }
`

export const Thumbnail = styled.div`
  min-width: 338px;
  min-height: 210px;
  height: 100%;
  border-radius: 4px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-right: 16px;
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-right: 0;
    min-width: 100%;
  }
`
export const Logo = styled.img`
  width: 100%;
  max-width: 512px;
`
