import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const LoadableImage = styled.div`
  transition: filter 1s ease;
  filter: ${props => (!props.loaded ? 'blur(3px)' : 'unset')};
  background: ${({ src, loaded, theme }) => (loaded ? `url(${src})` : theme.colors.black)};
  background-position: 50% 50%;
  background-origin: border-box;
  background-size: cover;
  opacity: ${({ loaded }) => (loaded ? 1 : 0.6)};

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const BlurImageLoader = ({ image, ...props }) => {
  const runOnce = true
  const [loadState, setLoadState] = useState({
    loaded: false,
  })

  useEffect(() => {
    const img = new Image()

    img.onload = function() {
      setLoadState({
        src: img.src,
        loaded: true,
      })
    }

    img.src = image
  }, [runOnce])
  return <LoadableImage {...props} {...loadState} />
}

BlurImageLoader.propTypes = {
  image: PropTypes.string.isRequired,
}
