import { Component } from 'react'
import PropTypes from 'prop-types'

import { TabsContext } from './TabsContext'

class Tab extends Component {
  componentDidMount() {
    const { context } = this.context
    const { id, title } = this.props
    context.addTab({
      id,
      title,
    })
  }

  render() {
    const {
      context: {
        activeTab: { id: activeTabId },
      },
    } = this.context
    const { children, id: tabId } = this.props
    return activeTabId === tabId && children
  }
}

Tab.contextType = TabsContext

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.shape({}).isRequired,
}
export default Tab
