import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { theme } from '../../styles'

const styleListItem = props =>
  props.isActiveTab
    ? css`
        background-color: ${theme.colors.orange};
        border: 2px solid ${theme.colors.bg};
      `
    : css`
        background-color: ${theme.colors.bg};
        border: 2px solid ${theme.colors.orange};
      `
const styleItem = props =>
  props.isActiveTab
    ? css`
        color: ${theme.colors.bg};
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        cursor: default;
      `
    : css`
        color: ${theme.colors.orange};
        cursor: pointer;
      `
export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 20;
  width: 100%;

  :before {
    content: '';
    display: block;
    background-color: ${theme.colors.orange};
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50%));
    height: 2px;
    width: 100%;
    z-index: -1;
  }
`

export const ListItem = styled.li`
  display: flex;
  justify-content: center;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  border-radius: 50%;
  width: 80px;
  @media (max-width: ${theme.breakpoints.m}) {
    width: 50px;
  }
  ${styleListItem};
`

export const Item = styled.a`
  display: block;

  font-weight: bold;
  padding: 25px;

  text-decoration: none;

  ${styleItem};
  @media (max-width: ${theme.breakpoints.m}) {
    padding: 12px;
  }
`
export const Container = styled.div`
  position: relative;
  /* border-bottom: 1px solid #b26a1d; */
`
