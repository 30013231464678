import styled from '@emotion/styled'

import { Button as DefaultButton } from '../../UI/Buttons'
import { Col as DefaultCol, Row as DefaultRow } from '../../UI/Grid'

export const Image = styled.div`
  min-height: 950px;
  height: 100%;
  width: 100%;
  min-width: 50%;
  background-size: cover;
  background-position: -120px center;
  background-repeat: no-repeat;

  background-image: ${({ src }) => `url(${src})`};
`

export const ImageWrapper = styled.div`
  position: absolute;
  top: 150px;
  right: -80px;
  max-width: 950px;
  width: 100%;
  height: 100%;

  @media (min-width: 1040px) and (max-width: 1270px) {
    right: -220px;
  }

  @media (min-width: 901px) and (max-width: 1039px) {
    right: -320px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 100%;
    right: 0;
    top: 0;
    position: relative;
    display: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  position: relative;

  #myClip {
    path {
      transform: rotate(-8deg);
    }
  }

  ${Image} {
    clip-path: url(#myClip);
  }
`

export const RowTitle = styled(DefaultRow)`
  padding: 88px 0 48px 0;
`
export const RowContent = styled(DefaultRow)`
  justify-content: space-between;
`
export const ColLeft = styled(DefaultCol)`
  max-width: 473px;
  padding: 150px 0;

  @media (min-width: 1040px) and (max-width: 1270px) {
    max-width: 380px;
  }
  @media (min-width: 901px) and (max-width: 1039px) {
    max-width: 340px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 100%;
    padding: 0;
  }
`

export const Button = styled(DefaultButton)`
  max-width: 216px;
  padding: 6px 16px;
`

export const SectionTitle = styled.h1`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: normal;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 2rem;
  }
`

export const Subtitle = styled.h2`
  font-size: 2rem;
  line-height: 1.4;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  span {
    color: ${({ theme }) => theme.colors.orange};
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Text = styled.p`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};

  padding: 32px 0;
`
