import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Hero from '../components/Homepage/Hero'
import TopSection from '../components/Homepage/TopSection'
import BreedSection from '../components/Homepage/BreedSection'
import TabsSection from '../components/Homepage/TabsSection'
import Partners from '../components/Homepage/Partners'
import LatestNews from '../components/LatestPosts'
import Reservation from '../components/Reservation'
import i18n from '../../config/i18n'

const Index = ({ data: { homepage, social, posts }, pageContext: { locale }, location }) => {
  const { slider } = homepage.data

  return (
    <>
      <SEO
        pathname={location.pathname}
        locale={locale}
        banner={slider[0].image.url}
        title={i18n[locale].seo.homepage.title}
      />
      <Hero data={slider} social={social} />
      <TopSection data={homepage.data} id="top" />
      <BreedSection data={homepage.data} />
      <TabsSection data={homepage.data} />
      <Partners data={homepage.data} />
      <LatestNews data={homepage.data} posts={posts.edges} />
      <Reservation />
    </>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
    homepage: PropTypes.object.isRequired,
    social: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        slider {
          image {
            url
          }
          slide_title
          description
          button
          link
        }
        top_section_title
        top_section_subtitle_1_black
        top_section_subtitle_1_orange
        top_section_subtitle_2_black
        top_section_subtitle_2_orange
        top_section_description
        top_section_button
        top_section_image {
          url
        }
        pedigree_section_title
        breed {
          title1
          description
          text_button
          link
          image {
            url
          }
        }
        interest_section_title
        interest_section_description
        steps {
          step_number
          step_title
          step_text
        }
        partners_title
        partners {
          image {
            url
          }
          name
          city
          description
        }
        news_title
        news_description
      }
    }
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: $locale } }, limit: 2) {
      edges {
        node {
          uid
          data {
            image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            description
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
