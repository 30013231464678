import React from 'react'
import PropTypes from 'prop-types'

import logo from '../../../../content/images/bubble-cat.png'
import SVG from '../../UI/SVG'

import { theme } from '../../../styles'

import * as S from './styled'

const BreedSection = ({ data }) => {
  const { pedigree_section_title: title, breed } = data

  return (
    <S.BackgroundWrapper>
      <S.Wrapper>
        <S.Row>
          <S.UpperCol>
            <S.Logo src={logo} alt="Bengalesisnika" />
            <S.Row>
              <S.Title>{title}</S.Title>
              <S.SvgWrapper>
                <SVG hiddenMobilehiddenMobile icon="circle" width={20} fill={theme.colors.black} left="88%" top="90%" />
                <SVG hiddenMobile icon="circle" width={20} fill={theme.colors.orange} left="95%" top="90%" />
              </S.SvgWrapper>
            </S.Row>
          </S.UpperCol>
        </S.Row>
        <S.ContentRow>
          {breed.map(example => {
            const {
              title1: breedTitle,
              description,
              text_button: button,
              link,
              image: { url },
            } = example
            return (
              <S.Col key={breedTitle}>
                <S.Card>
                  <S.CardRow>
                    <S.ThumbCol>
                      <S.Thumbnail src={url} alt={title} />
                    </S.ThumbCol>
                    <S.ContentCol>
                      <S.BreedTitle>{breedTitle}</S.BreedTitle>
                      <S.Text>{description}</S.Text>
                      <S.Link to={link}>
                        <S.Button>{button}</S.Button>
                      </S.Link>
                    </S.ContentCol>
                  </S.CardRow>
                </S.Card>
              </S.Col>
            )
          })}
        </S.ContentRow>
      </S.Wrapper>
    </S.BackgroundWrapper>
  )
}

BreedSection.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default BreedSection
