import React, { Component } from 'react'
import PropTypes from 'prop-types'

const TabsContext = React.createContext({
  context: {
    prevActiveTab: {},
    activeTab: {},
  },
})

class TabProvider extends Component {
  state = {
    tabs: [],
    prevActiveTab: {},
    // eslint-disable-next-line react/destructuring-assignment
    activeTab: this.props.activeTab,
  }

  addTab = tab => {
    const { tabs } = this.state
    const isTabExist = tabs.find(t => tab.id === t.id)

    if (!isTabExist) {
      this.setState(prevState => ({
        tabs: prevState.tabs.concat(tab),
      }))
    }
  }

  removeTab = tabId => {
    this.setState(prevState => ({
      tabs: prevState.tabs.filter(tab => tab.id !== tabId),
    }))
  }

  onClick = tab => () => {
    this.setState(prevState => ({
      prevActiveTab: prevState.activeTab,
      activeTab: tab,
    }))
  }

  render() {
    const { children } = this.props
    return (
      <TabsContext.Provider
        value={{
          context: {
            ...this.state,
            addTab: this.addTab,
            removeTab: this.removeTab,
            onClick: this.onClick,
          },
        }}
      >
        {children}
      </TabsContext.Provider>
    )
  }
}

const TabConsumer = TabsContext.Consumer

TabProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
  activeTab: PropTypes.shape({}).isRequired,
}

export { TabProvider, TabsContext, TabConsumer }
