import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TabProvider, TabConsumer } from './TabsContext'
import TabItem from './Tab'
import * as S from './styled'

const ListTabs = ({ children }) => <S.List>{children}</S.List>

const TabTitleItem = ({ children, innerRef, ...restProps }) => (
  <S.ListItem ref={innerRef} {...restProps}>
    {children}
  </S.ListItem>
)

const TabAnchorItem = ({ children, ...restProps }) => <S.Item {...restProps}>{children}</S.Item>

const TabsContainer = ({ children }) => <S.Container>{children}</S.Container>

const ReactTabs = ({ children, ...restProps }) => (
  <div
    style={{
      position: 'relative',
      width: '100%',
    }}
    {...restProps}
  >
    {children}
  </div>
)

class Tabs extends Component {
  static Tab = TabItem

  state = {
    tabsElements: [],
  }

  render() {
    const { children, activeTab } = this.props
    const { tabsElements } = this.state
    return (
      <TabProvider activeTab={activeTab}>
        <TabConsumer>
          {value => (
            <ReactTabs>
              <TabsContainer>
                <ListTabs>
                  {value.context.tabs.map(tab => (
                    <TabTitleItem
                      key={tab.id}
                      id={tab.id}
                      isActiveTab={value.context.activeTab.id === tab.id}
                      innerRef={tabElement => {
                        if (!tabsElements[tab.id]) {
                          this.setState(prevState => {
                            const { tabsElements: oldTabsElements } = prevState
                            oldTabsElements[tab.id] = tabElement

                            return {
                              oldTabsElements,
                            }
                          })
                        }
                      }}
                    >
                      <TabAnchorItem
                        isActiveTab={value.context.activeTab.id === tab.id}
                        // eslint-disable-next-line no-script-url
                        href="javascript:void(0)"
                        onClick={value.context.onClick(tab)}
                        onKeyPress={event => {
                          const code = event.keyCode || event.which

                          if (code === 13) {
                            this.onClick(tab)(event)
                          }
                        }}
                      >
                        {tab.title}
                      </TabAnchorItem>
                    </TabTitleItem>
                  ))}
                </ListTabs>
              </TabsContainer>

              {children}
            </ReactTabs>
          )}
        </TabConsumer>
      </TabProvider>
    )
  }
}

export default Tabs

ListTabs.propTypes = {
  children: PropTypes.node.isRequired,
}

TabTitleItem.propTypes = {
  innerRef: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

TabAnchorItem.propTypes = {
  children: PropTypes.node.isRequired,
}

TabsContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

ReactTabs.propTypes = {
  children: PropTypes.node.isRequired,
}

Tabs.propTypes = {
  activeTab: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
}
