import React from 'react'

import Carousel from 're-carousel'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Parallax from '../../UI/Parallax'

import IndicatorDots from '../../UI/Slider/indicator-dots'
import { BlurImageLoader } from './BackgroundImage'

import * as S from './styled'

const Hero = ({ data }) => (
  <S.Wrapper>
    <Carousel duration={1000} auto loop widgets={[IndicatorDots]}>
      {data.map(slide => {
        const {
          image: { url },
          slide_title: title,
          description,
          button,
          link,
        } = slide

        return (
          <S.HeroOutter key={title}>
            <Parallax force={2.2} offsetComp={0}>
              {offSet => (
                <BlurImageLoader
                  image={url}
                  style={{
                    transform: `translateY(${offSet}px) translateZ(0)`,
                  }}
                >
                  <S.Overlay />
                  <S.HeroTitle>{title}</S.HeroTitle>
                  <S.HeroText>{description}</S.HeroText>
                  <Link to={link}>
                    <S.Button type="button">{button}</S.Button>
                  </Link>
                  <Link to="/#top">
                    <S.Arrow />
                  </Link>
                </BlurImageLoader>
              )}
            </Parallax>
          </S.HeroOutter>
        )
      })}
    </Carousel>
  </S.Wrapper>
)

Hero.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
export default Hero
