import React from 'react'
import PropTypes from 'prop-types'

const Dot = ({ selected }) => (
  <span
    style={{
      display: 'inline-block',
      height: '13px',
      width: '13px',
      borderRadius: '50%',
      border: '2px solid white',
      backgroundColor: selected ? 'white' : 'transparent',
      margin: '0 13px',
      opacity: selected ? '1' : '0.3',
      transitionDuration: '300ms',
    }}
  />
)

const IndicatorDots = ({ index, total }) => {
  const wrapperStyle = {
    position: 'absolute',
    width: '100%',
    zIndex: '100',
    top: '50%',
    textAlign: 'center',
    transform: 'rotate(90deg) translateY(-50%)',
    left: 'calc(50% - 36px)',
  }

  if (total < 2) {
    // Hide dots when there is only one dot.
    return <div style={wrapperStyle} />
  }
  return (
    <div style={wrapperStyle}>
      {Array(total)
        .fill(' ')
        .map((x, i) => (
          <Dot key={i} selected={index === i} />
        ))}
    </div>
  )
}

export default IndicatorDots

Dot.propTypes = {
  selected: PropTypes.bool.isRequired,
}
IndicatorDots.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}
