import styled from '@emotion/styled'

import quotes from '../../../../content/icons/quotes.svg'

export const Wrapper = styled.section`
  position: relative;
  width: 100vw;
  overflow: hidden;
  padding: 140px 0 0;

  :before {
    @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
      content: '';
      display: block;
      background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 17%, #ffffff 62%);
      border-radius: 5px;
      width: 20%;
      height: 60%;
      position: absolute;
      z-index: 10;
      left: 0;
      bottom: 0;
      transform: translateY(1%);
      pointer-events: none;
    }
  }

  :after {
    @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
      content: '';
      display: block;
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 61%);
      border-radius: 5px;
      width: 25%;
      height: 60%;
      position: absolute;
      z-index: 10;
      right: 0;
      bottom: 0;
      transform: translateY(1%);
      pointer-events: none;
    }
  }
`

export const Bubbles = styled.img`
  position: absolute;
  top: -80px;
  right: 0;

  @media (max-width: 690px) {
    display: none;
  }
`

export const InnerWrapper = styled.div`
  position: relative;
  max-width: 1088px;
  margin: 0 auto;
  padding: 0 16px;
  @media screen and (min-width: 600) {
    padding: 0 48px;
  }
`

export const Slider = styled.div`
  margin-top: 32px;
  margin-bottom: 72px;
  @media screen and (min-width: 900px) {
    margin-top: 64px;
  }
  position: relative;
`

export const SliderSCroll = styled.div`
  display: flex;
  transition: transform 0.2s ease;
  margin-right: -16px;
  overflow: auto;
  @media screen and (min-width: 900px) {
    overflow: visible;
  }
`

export const Card = styled.div`
  box-shadow: -1px 13px 20px 0 #f0f2f4;
  border-radius: 5px;

  background-color: #ffffff;

  padding: 32px;
  margin-right: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-bottom: 40px;
  }
`

export const CardImage = styled.div`
  height: 72px;
  width: 72px;
  margin-bottom: 16px;
  border-radius: 50%;

  background-image: url(${({ src }) => src});
  background-size: cover;
`

export const Nav = styled.div`
  position: absolute;
  bottom: -70px;
  left: 0;
  z-index: 100;
  display: none;
  @media screen and (min-width: 900px) {
    display: block;
  }
  button {
    margin-left: 0.5em;
    cursor: pointer;
    outline: none;
  }
`

export const Text = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`

export const City = styled(Text)`
  color: ${({ theme }) => theme.colors.orange};
`

export const Description = styled.p`
  padding-top: 8px;
  font-size: 0.8rem;
  max-height: 134px;
  overflow: scroll;
`

export const Heading = styled.h2`
  position: relative;
  color: ${({ theme }) => theme.colors.black};
  max-width: 411px;

  :before {
    content: '';
    display: block;
    background-image: url(${quotes});
    width: 155px;
    height: 108px;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    pointer-events: none;
  }
`
