import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.1;
  background-color: ${({ theme }) => theme.colors.bg};
`
