import React from 'react'
import PropTypes from 'prop-types'

import Tabs from '../../Tabs'
import Bubbles from '../../shared/Bubbles'

import * as S from './styled'

const TabsSection = ({ data }) => {
  const { interest_section_title: title, interest_section_description: description, steps } = data

  return (
    <S.Wrapper>
      <Bubbles />
      <S.InnerWrapper>
        <S.Title>{title}</S.Title>
        <S.Text>{description}</S.Text>
        <Tabs
          activeTab={{
            id: steps[0].step_number,
          }}
        >
          {steps.map(step => {
            const { step_title: stepTitle, step_number: stepNumber, step_text: text } = step

            return (
              <Tabs.Tab key={stepNumber} id={stepNumber} title={stepNumber}>
                <S.Row>
                  <S.Col>
                    <S.StepTitle>{stepTitle}</S.StepTitle>
                  </S.Col>
                  <S.Col>
                    <S.Text>{text}</S.Text>
                  </S.Col>
                </S.Row>
              </Tabs.Tab>
            )
          })}
        </Tabs>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

TabsSection.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default TabsSection
