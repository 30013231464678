import styled from '@emotion/styled'

import { Button as DefaultButton } from '../../UI/Buttons'
import arrow from '../../../../content/icons/arrow.svg'

export const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.bg};
  display: flex;
  align-items: center;
  height: calc(100vh - 115px);

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    height: calc(100vh - 65px);
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  opacity: 0.17;
  background: ${({ theme }) => theme.colors.black};

  z-index: 200;
  pointer-events: none;
`
export const Button = styled(DefaultButton)`
  max-width: 216px;
  padding: 6px 16px;
  position: relative;
  z-index: 210;
`
export const HeroOutter = styled.div`
  height: 100%;
  width: 100%;
`
export const HeroInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 1.5rem;
  }
`

export const HeroImage = styled.img`
  position: absolute;
  width: auto;
  height: 100%;
  z-index: -1;
`

export const HeroTitle = styled.div`
  font-size: 3rem;
  font-weight: bold;
  position: relative;
  z-index: 210;
  color: ${({ theme }) => theme.colors.bg};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 2rem;
  }
`

export const Arrow = styled.div`
  position: fixed;
  bottom: 20%;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  background-image: url(${arrow});
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 210;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
`

export const HeroText = styled.div`
  font-size: 2rem;
  line-height: 1.4;
  position: relative;
  z-index: 210;

  color: ${({ theme }) => theme.colors.bg};
  margin-bottom: 2rem;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`
